<template>
    <div>
        <!-- Bookmark Popup  -->
        <first-run :which="whichPop" :onClose="onCloseHelp" :onNext="onNextPop" :doSearch="doSearch"
        :pEngine.sync="productEngine" :saveSearchUrl="true" :firstRunSettings="branding.FirstRunSettings" :q="query" />
        <!-- First Run Popup -->
        <!----search module----->
        <div class="searchModule">
            <div class="sLogo"><img :src="branding.Logo"></div>
            <div class="search web">
                <input ref="queryInput" v-model='query' @return="toProductSearch()" @keydown.enter="toProductSearch()" :placeholder="branding.AutocompletePlaceholder"/>
                <div class="s-icon"><i class="fa fa-search"></i></div>
                <div class="error" v-if="queryError">This field is required, please enter</div>
            </div>
            <div class="sBtnContainer">
                <button class="btn-search productSearch" @click="toProductSearch()">{{branding.SearchLabel}}</button>
                <button class="btn-search webSearch" id="websrch-btn" @click="startWebSearch()">Web Search</button>
            </div>
        </div>
        <!----end oksearch module----->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter"><img :src="branding.Logo"><span><i class="fa fa-copyright" aria-hidden="true"></i> <span id="brandDetails"> {{branding.Trademark}}</span> </span></div>
                <ul>
                    <li><a :href="`https://${branding.RootDomain}/terms-of-service/`">Terms of Service </a></li>
                    <li><a :href="`https://${branding.RootDomain}/privacy-policy/`">Privacy</a></li>
                    <li><a :href="`https://${branding.RootDomain}/contact-us/`">Contact Us</a></li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
</template>

<script>
    "use strict"
    import bp  from "babel-polyfill"
    import queryString  from 'query-string'
    import 'whatwg-fetch'
    import Humanize from 'humanize-plus'
    import {getGuid, getSetting, setSetting} from "../storage"
    import {WEB_SEARCH_URL, openUrl}  from '../urls'
    import FirstRun from '../components/FirstRun.vue'
    import {getBranding, PRODUCT_SEARCH_PROVIDERS, DEFAULT_PRODUCT_SEARCH_PROVIDER}  from "../branding"
    import {documentReady} from '../common'

    function validatePop(name){
        switch(name){
            case "intro":
            case "i":
                return "intro"
            case "t":
            case "tutorial":
                return "tutorial"
            case "it":
                return "intro-tutorial"
        }

        return false;
    }

    export default {
        name: 'serp',
        components:{
            'first-run':FirstRun
        },
        data(){
            let qs  = queryString.parse(window.location.search);
            return {
                query: qs.q || '',
                queryError: false,
                recentSearches: [],
                guid: qs.guid,
                whichPop: validatePop(qs.pop) || false ,
                branding: getBranding(),
                productEngine: qs.engine,
            }
        },
        mounted(){
            documentReady.then(async () => {
                this.recentSearches = JSON.parse(getSetting("recentSearches")) || [];
                try {
                    this.guid = await getGuid();
                }
                catch (e) {
                    // Extension not available.
                }
                this.$refs.queryInput.focus();
            })
        },
        methods: {
            startWebSearch() {
                let searchParams= {
                    k:this.query,
                    guid:this.guid
                }
                openUrl(`${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`, false);
            },
            validateSearch(withFocus) {
                let {query} = this;
                let errs=false;
                if(query == ""){
                    errs=  errs  || {}
                    errs.query = "This field is required, Please enter"
                }
                this.queryError=errs;
                if(withFocus){
                    if(errs.query)  this.$refs.queryInput.focus();
                }
                return errs === false;
            },
            async toProductSearch() {
                if(!this.validateSearch(true)) return;
                if (this.recentSearches.unshift(this.query) > 5) {
                    this.recentSearches.pop();
                }
                setSetting("recentSearches", JSON.stringify(this.recentSearches));
                await this.doSearch(this.query);
            },
            async doSearch(term) {
                await openUrl(PRODUCT_SEARCH_PROVIDERS[this.productEngine || DEFAULT_PRODUCT_SEARCH_PROVIDER].url.replace("{{QUERY}}",encodeURIComponent(term)), false);
            },
            onNextPop(tag) {
                this.whichPop=validatePop(tag) || false;
            },
            onCloseHelp() {
                switch(this.whichPop){
                    case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
                }
                this.whichPop=false
            },
        }
    }
</script>

<style>
    @import './css/search.css';
</style>
