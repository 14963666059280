const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "Notes App",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "Search Notes",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "Notes",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "Project Status",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Enter keyword to search notes",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1: "Get started with searching your notes or add new notes from your selected app.",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2: "",
    FirstRunSettings: true,
}

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    google: {
        name: "Google Keep",
        id: "google",
        isDefault:true,
        url: "https://keep.google.com/#search/text={{QUERY}}"
    },
    evernote: {
        name: "Evernote",
        id: "evernote",
        isDefault: false,
        url: "https://www.evernote.com/Home.action#n=&s=&ses=1&sh=&sds=&x={{QUERY}}",
    }
}

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(([key, value]) =>  !value.isDefault);
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        }
    });
}

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS["google"].id;

function getBranding(){
    return {
        ...defaultBranding,
        ...windowBranding,
    }
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
}
