<template>
    <!--Light Box-->
    <div class="lightbox" v-if="which">
        <!-- Setting panel-->
        <div class="card firstrun selectServices test" v-if="firstRunSettings && showSettings && showEngineSelectionSettings">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img :src="branding.Logo">
            <p>Notes App works with listed online Notes applications to provide easy and quick notes search. Please select the app you want to use for your Notes.</p>
            <small>*Please note that this app may require you to create a free account. You can change your selection from settings at any time.</small>
            <ul>
                <li v-for="provider in productSearchProviders">
                    <label>
                        <input type="radio" v-model="productEngine" :value="provider.id" :checked="provider.id === productEngine">
                        {{provider.name}}
                        <span v-if="provider.isDefault">(Default)</span>
                    </label>
                </li>
            </ul>
            <div class="search web">
                <input ref="queryInput" v-model='query' @return="saveSetting()" @keydown.enter="saveSetting()" :placeholder="branding.AutocompletePlaceholder"/>
                <div class="s-icon"><i class="fa fa-search"></i></div>
                <div class="error" v-if="queryError">This field is required, please enter</div>
            </div>
            <div class="btnWrapperRight">
                <button class="ok left" @click="saveSetting()" >Search notes</button>
            </div>
        </div>
        <div class="card firstrun" v-if="firstRunSettings && showSettings && !showEngineSelectionSettings">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <p id="hc"> Start searching your <br> notes from  {{engineName}} </p>
            <p id="sub">Please note, you may be asked to sign into your {{engineName}} account</p>
                <!----search module----->
            <div id="searchModule-new" class="searchModule">
                <div class="search web">
                    <input ref="queryInput" v-model='query' @return="onSearch()" @keydown.enter="onSearch()" :placeholder="branding.AutocompletePlaceholder"/>
                    <div class="s-icon"><i class="fa fa-search"></i></div>
                    <div class="error" v-if="queryError">This field is required, please enter</div>
                </div>
                    <button id="new-button" class="btn-search productSearch" @click="onSearch()">{{branding.SearchLabel}}</button>
            </div>
            <!----End search module----->
        </div>
        <!-- End of Setting panel-->
        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img :src="branding.Logo">
            <h1>Getting the most out of {{branding.ProductName}}</h1>
            <img :src="branding.TypeGif" class="type">
            <ol>
                <li>Go to the address bar and type <strong>"{{branding.MetaSearchKeyword}}"</strong></li>
                <li>After <strong>"{{branding.MetaSearchKeyword}}"</strong> hit spacebar then type title of your note or any text with the note.<br> (e.g. <strong>{{branding.SearchExample}}</strong>)</li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()">OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
    <!--End of light box-->
</template>
<script>
    "use strict";
    // Import search

    import {getBranding, PRODUCT_SEARCH_PROVIDERS, DEFAULT_PRODUCT_SEARCH_PROVIDER} from '../branding'
    import {getExtSetting, setExtSetting} from "../storage"
    import {arrow} from '@tightrope/newtab'
    import queryString  from 'query-string'
    import {openUrl}  from '../urls'

    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(search, this_len) {
            if (this_len === undefined || this_len > this.length) {
                this_len = this.length;
            }
            return this.substring(this_len - search.length, this_len) === search;
        };
    }
    export default {
        name:'first-run',
        props:["which", "q", "onClose", "onNext", "doSearch", "pEngine", "saveSearchUrl", "firstRunSettings"],
        data(){
            let qs  = queryString.parse(window.location.search);
            return {
                query: this.q || '',
                queryError: false,
                productEngine: this.pEngine,
                productSearchProviders: PRODUCT_SEARCH_PROVIDERS,
                branding: getBranding(),
                showSettings: this.saveSearchUrl
            }
        },
        async created() {
             if (this.firstRunSettings && this.productEngine) {
                try {
                    if (!PRODUCT_SEARCH_PROVIDERS[this.productEngine]) {
                        this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER;
                    }
                    await setExtSetting("productEngine", PRODUCT_SEARCH_PROVIDERS[this.productEngine].id);
                    if (this.saveSearchUrl) {
                        await setExtSetting("productEngineUrl", PRODUCT_SEARCH_PROVIDERS[this.productEngine].url);
                    }
                }
                catch(e) {
                    // no extension
                }
                finally {
                    this.$emit('update:pEngine', this.productEngine);
                }
            }
            else if (this.firstRunSettings && !this.productEngine) {
                try {
                    this.productEngine = await getExtSetting("productEngine");
                    if (this.productEngine) {
                        this.showSettings = false;
                        this.$emit('update:pEngine', this.productEngine);
                    }
                    else {
                        this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER;
                    }
                }
                catch(e) {
                    // no extension
                    this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER
                }
            }
        },
        mounted() {
            arrow();
        },
        methods:{
            canShow(name){
                return  this.which && this.which.endsWith(name)
            },
            pickLearnTips(){
                switch(this.which){
                    case 'intro':
                        this.onNext('it');
                    return;
                }
            },
            onCloseClick(){
                this.onClose && this.onClose(...arguments);
            },
            async onSearch(){
                if(!this.validateSearch(true)) return;
                    await this.doSearch(this.query);
                this.onCloseClick();
            },
            validateSearch(withFocus) {
                let {query} = this;
                let errs=false;
                if(query == ""){
                    errs=  errs  || {}
                    errs.query = "This field is required, Please enter"
                }
                this.queryError=errs;
                if(withFocus){
                    if(errs.query)  this.$refs.queryInput.focus();
                }
                return errs === false;
            },
            async saveSetting() {
                try {
                    await setExtSetting("productEngine", PRODUCT_SEARCH_PROVIDERS[this.productEngine].id);
                    if (this.saveSearchUrl) {
                        await setExtSetting("productEngineUrl", PRODUCT_SEARCH_PROVIDERS[this.productEngine].url);
                    }
                }
                catch(e) {
                    // no extension
                }
                this.$emit('update:pEngine', this.productEngine);
                if(!this.validateSearch(true)) return;
                await this.doSearch(this.query);
                this.showSettings = false;
                this.onCloseClick();
            }
        },
        computed:{
            isTutorial() { return this.canShow('tutorial'); },
            isIntro() { return this.canShow('intro'); },
            engineName() { return PRODUCT_SEARCH_PROVIDERS[this.productEngine].name; },
            showEngineSelectionSettings() { return !this.pEngine }
        }
    };
</script>